import { createEffect, createMemo, createSignal, Show, Suspense, useContext } from 'solid-js';
import { ActivityIndicator, TextLink, Container, HorizontalRule, SectionStep, Heading, LinkButton } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, useAction, useLocation, useParams, useSubmissions } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { produce } from 'solid-js/store';
import { useUser } from '../../../providers/user';
import { logout } from '../../auth/components/logout-action';
import { AuthFlow, useAuthStore } from '../../../partials/auth/auth';
import { gql, mutationAction, useMutation } from '../../../graphql';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { AccessProductsCtx } from '../../../providers/card';
import { useStripe } from '../../../providers/stripe';
import { NotFoundContent } from '../../../partials/404';
import { usePersisted } from '../../../providers/persistence-store';
import { ErrorBoundary } from '../../../components/error-boundary';
import { useUtmParams } from '../../../providers/utm';
import { StripeSubmit } from '../../../components/stripe/submit';
import { objectToFormData } from '../../../graphql/form-data';
import { Elements } from '../../../components/stripe/elements';
import { Receipt } from './_components/receipt';
import { ProductInfo } from './_components/product-info';
import { PromoCodeEntry } from './_components/promo-code-entry';
import type { FlowStep } from '../../../partials/auth/flow';
import type { JSX } from 'solid-js';
import type { StripeError } from '@stripe/stripe-js';

export default function CardCheckout() {
	const params = useParams<{ id: string }>();
	const location = useLocation<{ redirect?: string }>();
	const user = useUser();
	const products = useContext(AccessProductsCtx);
	const trackEvent = useTrackEvent();
	const handleLogout = logout(trackEvent, '/card/checkout');
	const [errors, setErrors] = createSignal<StripeError | undefined>();
	const [enteredCode, setEnteredCode] = createSignal(false);
	const [persisted, setPersisted] = usePersisted();
	const utm = useUtmParams();

	const { setAmount, stripe } = useStripe();

	const productInfo = createMemo(() => {
		return products()?.find((card) => card.id === params.id);
	});

	const createIntent = useMutation(setupIntent);
	const runCreateIntent = useAction(createIntent);
	const subs = useSubmissions(createIntent);
	const currentSub = createMemo(() => subs[subs.length - 1]);
	const lastValidSub = createMemo(() => {
		return subs.findLast((sub) => !sub.pending && !sub.error && !sub.result?.error) ?? subs[subs.length - 1];
	});

	createEffect(() => {
		if (
			persisted.promoCode?.value &&
			currentSub()?.result?.error?.graphQLErrors?.find((e) => {
				return (
					typeof e.extensions?.displayMessage === 'string' &&
					e.extensions.displayMessage.toLowerCase().includes('promo code')
				);
			})
		) {
			setPersisted(
				produce((s) => {
					delete s.promoCode;
				}),
			);
		}
	});

	createEffect(() => {
		const total = currentSub()?.result?.data?.subscription.totalAmount;
		if (total) {
			setAmount(total);
		}
	});

	createAsync(
		async () => {
			if (!user()?.me || !params.id) {
				return;
			}

			const data = objectToFormData({
				productId: params.id,
				promoCode: persisted.promoCode?.value,
				utm: utm(),
			});

			await runCreateIntent(data);
		},
		{ deferStream: false },
	);

	return (
		<Show when={productInfo()} fallback={<NotFoundContent />}>
			{(productInfo) => (
				<>
					<div class="-mb-6 -mt-8 md:-mb-12 lg:hidden">
						<ProductInfo {...productInfo()} />
					</div>
					<Container>
						<Grid>
							<GridSeven class="flex flex-col gap-12">
								<div class="flex flex-col gap-4">
									<Heading as="h1" size="h2" class="sr-only md:not-sr-only">
										Join Troon Access
									</Heading>

									<p class="flex flex-wrap items-center justify-stretch gap-2 rounded bg-brand-100 p-4">
										<Icon name="gift" class="text-lg text-brand" />
										<span class="grow">Looking to give the gift of Troon Access?</span>
										<LinkButton href={`/access/gift/${params.id}`}>
											Buy as a Gift <Icon name="arrow-right-md" />
										</LinkButton>
									</p>
								</div>

								<section class="flex flex-col gap-4">
									<Show
										when={user()}
										fallback={
											<div class="flex flex-col gap-4">
												<SectionStep step={1} state="current">
													Log in or sign up
												</SectionStep>
												<AuthFlow inline headers={authHeaders} showSteps={false} />
											</div>
										}
									>
										{(user) => (
											<>
												<SectionStep
													step={1}
													state="completed"
													action={
														<TextLink
															href="/auth/logout"
															onClick={(e) => {
																e.preventDefault();
																handleLogout();
															}}
														>
															Log out
														</TextLink>
													}
												>
													Account information
												</SectionStep>

												<ul class="flex flex-col gap-1">
													<li>
														{user().me.firstName} {user().me.lastName}
													</li>
													<li>{user().me.email}</li>
													<li>{user().me.troonRewardsId}</li>
												</ul>
											</>
										)}
									</Show>
								</section>

								<section class="flex flex-col gap-4">
									<SectionStep step={2} state={user() ? 'current' : 'waiting'}>
										Payment information
									</SectionStep>

									<ErrorBoundary>
										<Show when={stripe.state !== 'errored'}>
											<div classList={{ hidden: !user()?.me }}>
												<Elements />
											</div>
										</Show>
									</ErrorBoundary>
								</section>
							</GridSeven>

							<GridFive>
								<div class="overflow-y-hidden border-neutral lg:rounded lg:border">
									<div class="hidden overflow-hidden lg:block">
										<ProductInfo {...productInfo()} />
									</div>

									<Show when={user()}>
										<div data-testId="receipt" class="flex flex-col gap-6 py-6 lg:p-6">
											<Suspense fallback={<ActivityIndicator />}>
												<Show when={lastValidSub()?.result?.data?.subscription} fallback={<ActivityIndicator />}>
													{(subscription) => (
														<>
															<PromoCodeEntry
																action={createIntent}
																// @ts-expect-error TODO not sure why this is complaining
																document={query}
																promoCode={subscription().promoCode as string | undefined}
																intentData={{ productId: params.id }}
																onReceivePromoSubscription={() => {}}
																onUpdatePromoCode={(code) => setEnteredCode(!!code)}
															/>
															<Receipt
																discount={subscription().discountAmount}
																subtotal={subscription().subtotalAmount}
																tax={subscription().taxAmount}
																total={subscription().totalAmount}
																promoCode={subscription().promoCode}
															/>
														</>
													)}
												</Show>
											</Suspense>

											<HorizontalRule />

											<Show when={lastValidSub()?.result?.data?.subscription}>
												{(subscription) => <p class="text-sm text-neutral-800">{subscription()?.disclosure}</p>}
											</Show>

											<StripeSubmit
												productId={params.id!}
												redirectPath={location.state?.redirect ?? '/access/checkout/confirm'}
												disabled={
													(enteredCode() && !currentSub()?.result?.data?.subscription.promoCode) ||
													currentSub()?.pending ||
													!!currentSub()?.result?.error
												}
												onError={(errors) => setErrors(errors)}
												stripeClientSecret={currentSub()?.result?.data?.subscription.stripeClientSecret}
												stripeClientSecretIntentType={
													currentSub()?.result?.data?.subscription.stripeClientSecretIntentType
												}
												troonCardSubscriptionId={currentSub()?.result?.data?.subscription.troonCardSubscriptionId}
											>
												Subscribe & pay
											</StripeSubmit>

											<Show when={errors()}>
												{(error) => (
													<p class="text-red-500" aria-live="assertive">
														There was a problem completing the request: {error().message}
													</p>
												)}
											</Show>

											<p class="text-center text-sm">
												By clicking “Subscribe & pay”, you are agreeing to Troon’s{' '}
												<TextLink target="_blank" href="/access/terms">
													Terms of Service
												</TextLink>{' '}
												and{' '}
												<TextLink target="_blank" href="https://troon.com/privacy-policy/">
													Privacy Policy
												</TextLink>
												.
											</p>
										</div>
									</Show>
								</div>
							</GridFive>
						</Grid>
					</Container>
				</>
			)}
		</Show>
	);
}

const query = gql(`mutation setupTroonCardSubscription(
  $productId: String!
  $promoCode: String
	$utm: UTMAttributesInput
) {
  subscription: setupTroonCardSubscription(
    troonCardSubscriptionProductId: $productId
    promoCode: $promoCode
    utmAttributes: $utm
  ) { ...Subscription }
}`);

const setupIntent = mutationAction(query);

const authHeaders: Record<FlowStep, () => JSX.Element> = {
	'/auth': () => (
		<p>
			Enter the email associated with your Troon Card/Troon Rewards Account to log in. If you don’t have one, you can
			create a new account for free.
		</p>
	),
	'/auth/login': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We found an existing Troon Rewards account for <b>{store.data.email}</b>. Enter your password to login and
					continue.
				</p>
			</>
		);
	},
	'/auth/join': () => {
		return (
			<>
				<FoundUser />
				<p>Complete the following account information to set up your Troon Account.</p>
			</>
		);
	},
	'/auth/reset': () => (
		<>
			<FoundUser />
			<p>Check your email for a confirmation code.</p>
		</>
	),
	'/auth/reset/password': () => (
		<>
			<FoundUser />
			<p>Create a password with at least 8 letters and numbers.</p>
		</>
	),
	'/auth/magic-link': () => {
		const [store] = useAuthStore();
		return (
			<>
				<FoundUser />
				<p>
					We sent a login code to <b>{store.data.email}</b>. Copy the code and enter it below to continue logging in.
				</p>
			</>
		);
	},
};

export { authHeaders, query, setupIntent };

function FoundUser() {
	const [store] = useAuthStore();
	return (
		<p class="flex flex-row items-center gap-2 rounded border border-neutral-300 bg-neutral-100 px-4 py-3 text-neutral-800">
			<Icon name="circle-check" class="size-6 text-green-500" />
			{store.data.email}
		</p>
	);
}
